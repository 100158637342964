<template>
    <div class="grid">
      <div>
        <article>
          <header>Auto Fights Programmed</header>
          <table>
            <thead>
              <tr>
                <th>Farmer</th>
                <th>Type/LeekId</th>
                <th>Fight Count</th>
                <th>Last Run</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="auto in autoFights" v-bind:key="auto.login + auto.type + auto.fightCount">
                <td>{{ auto.login }}</td>
                <td>{{ auto.type }}</td>
                <td>{{ auto.fightCount }}</td>
                <td>{{ auto.lastDone == 0 ? 'Never' : new Date(auto.lastDone.seconds * 1000) }}</td>
              </tr>
            </tbody>
          </table>
        </article>
      </div>
      <div>
        <article>
          <header>Add Auto Fights</header>
          <form v-on:submit="sheduleFights">
          <fieldset>
            <label for="farmer">Farmer</label>
            <select id="farmer" v-model="selectedFarmer">
              <option v-for="farmer in farmers" v-bind:key="farmer.id" :value="farmer">{{ farmer.name }}</option>
            </select>
          </fieldset>
          <fieldset v-if="selectedFarmer != null">
            <label for="leek">Leek</label>
            <select id="leek" v-model="selectedLeek">
              <option value="None">Farmer Fight</option>
              <option v-for="leek in selectedFarmer.leeks" v-bind:key="leek.id" :value="leek.id">{{ leek.name }}</option>
            </select>
          </fieldset>
          <fieldset v-if="selectedFarmer != null">
            <label for="fights">Fight Count
              <input type="range" min="0" max="100" value="50" id="fights" name="fights" v-model="fightCount">
            </label>
            {{ fightCount }} Fights
          </fieldset>
          <button v-if="selectedFarmer != null" type="submit">Shedule Fights</button>
        </form>
        </article>
      </div>
    </div>
</template>

<script>
import { collection, addDoc, query, where, onSnapshot } from "firebase/firestore";
import Vue from "vue";
import store from "@/store";

export default {
  name: 'HelloWorld',
  props: {
    farmers: Array,
    requestFarmers: Function,
  },
  data() {
    return {
      user: store.getters.getUser,
      selectedFarmer: null,
      selectedLeek: null,
      fightCount: 0,
      autoUnSub: null,
      autoFights: [],
    }
  },
  methods: {
    sheduleFights(e) {
      e.preventDefault();

      let uid = this.user.uid;
      let login = this.selectedFarmer.login;
      let type = this.selectedLeek;
      let fightCount = this.fightCount;

      addDoc(collection(Vue.firebase.db, "LW_AUTO"), {
        uid: uid,
        login: login,
        type: type,
        lastDone: 0,
        fightCount: fightCount,
        status: 0,
        fightDone: [],
      });
      this.requestFarmers();
    }
  },
  mounted() {
    this.$nextTick(function () {
      const q = query(collection(Vue.firebase.db, "LW_AUTO"),
        where("uid", "==", this.user.uid)
      );
      this.autoUnSub = onSnapshot(q, (snap) => {
        const autos = [];
        snap.forEach((doc) => {
          autos.push(doc.data());
        });
        this.autoFights = autos;
      });
    });
  }
}

/**
 * 
 * LW_AUTO
 * login: accountLogin
 * type: LeekId (INT) / None
 * lastDone
 * ____________________ COMMON WITH MANUAL FIGHT _________________
 * status: 0 (Waiting), (1 Done), (2 Error)
 * fightCount: INT
 * fightDone: [] array of Fight
 *
**/

</script>